.queue-list {
    top: 10px !important;
    min-width: 170px;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);

    .ui-popup__content__content {
        padding: 0;
    }

    .queue-list-item {
        min-height: 36px;
        padding: 0;

        &.readonly {
            cursor: not-allowed;
            color: grey;
            &:hover {
                color: #efefef;
            }
        }

        .ui-list__item__content {
            margin-right: 0;
        }
    }
}
.queues-popup-trigger {
    &.no-joined-queue {
        background-color: red;

        &:hover {
            background-color: #d80909;
        }
    }
}

.notification-count {
    background-color: #cc4a31 !important;
    color: white !important;
    width: 18px !important;
    height: 18px !important;
    min-width: 0px !important;
    min-height: 0px !important;
    position: absolute !important;
    top: -8px !important;
    z-index: 2 !important;
    right: -5px !important;

    span {
        font-size: 11px !important;
    }
    &:hover {
        background-color: #cc4a31 !important;
        color: white !important;
    }
}
.voicemail-notification-count {
    background-color: #cc4a31 !important;
    color: white !important;
    width: 16px !important;
    height: 16px !important;
    min-width: 0px !important;
    min-height: 0px !important;
    position: absolute !important;
    top: -1px !important;
    z-index: 2 !important;
    right: 1px !important;

    span {
        font-size: 10px !important;
    }
    &:hover {
        background-color: #cc4a31 !important;
        color: white !important;
    }
}
.media-cm-icon {
    width: 33px;
    height: 33px;
}
.default-app-theme {
    .media-cm-icon {
        content: url(/assets/themes/default/whatsapp-icon.svg);
        &:hover {
            content: url(/assets/themes/default/whatsapp-icon.svg);
            opacity: 0.9;
        }
    }
}

.dark-app-theme {
    .media-cm-icon {
        content: url(/assets/themes/dark/whatsapp-icon.svg);
        &:hover {
            content: url(/assets/themes/dark/whatsapp-icon.svg);
            opacity: 0.9;
        }
    }
}

.contrast-app-theme {
    .media-cm-icon {
        content: url(/assets/themes/contrast/whatsapp-icon.svg);
        &:hover {
            content: url(/assets/themes/contrast/whatsapp-icon.svg);
            opacity: 0.9;
        }
    }
}

@media (max-width: 960px) {
    .voicemail-notification-count,
    .realtime-reports-button,
    .voice-mails-button {
        display: none !important;
    }
}
