.second-row {
    margin-top: 10px;
}

.row-label {
    color: #484644
}

.estimated-wait-time-input input {
    padding-right: 12px;
}