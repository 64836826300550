.visible-item{
    display:block !important;
}

.hidden-item{
    display:none !important;
}

.view-icon{
    margin-left: 10px;
    margin-right:10px;
}
.bold-text{
    font-weight: 900!important;   
}
.voice-mail-action-buttons-container{
    display: inline-block !important;
    margin-top: 10px;
    .voice-mail-action-buttons{
        float: right !important;
    }
}
.react-player-item{
    max-width: 100%;
}