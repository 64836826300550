.active-tab {
    display: block !important;
}

.hidden-tab {
    display: none !important;
}

.tab-navigation {
    background-color: white;
    margin-bottom: 20px;
    padding: 0 5px;

    .tab-navigation-button {
        margin: 0 5px;
        cursor: pointer;
    }
}

.menu-tabs-container {
    position: sticky;

    .menu-tabs {
        border-bottom: none;
    }
}

.notifications-body-container {
    overflow-y: scroll;
}

.alerts-notification-tab-count,
.voicemail-notification-tab-count {
    background-color: #cc4a31 !important;
    color: white !important;
    width: 16px !important;
    height: 16px !important;
    min-width: 0px !important;
    min-height: 0px !important;
    position: absolute !important;
    top: 8px !important;
    z-index: 2 !important;

    span {
        font-size: 10px !important;
    }

    &:hover {
        background-color: #cc4a31 !important;
        color: white !important;
    }
}

.alerts-notification-tab-count {
    right: 2px !important;
}

.voicemail-notification-tab-count {
    right: -16px !important;
}

.alerts-tab-header {
    margin-right: 15px;
}

.voicemail-notification-tab-count-extended {
    background-color: #cc4a31 !important;
    color: white !important;
    margin-left: 5px;
    width: 16px !important;
    height: 16px !important;
    min-width: 0px !important;
    min-height: 0px !important;
    position: absolute !important;
    z-index: 2 !important;
    right: 1px !important;
    top:5px !important;

    span {
        font-size: 10px !important;
    }
}