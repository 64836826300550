.presence-status {
  margin-right: 10px;
  width: 10px;
  height: 10px;
}

.presence-text-size {
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 200px;
}

.presence-with-reasons>span {
  width: 100%;
}

.presence-with-reasons-popup-trigger {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.presence-with-reasons-popup-trigger:focus {
  outline: 0;
}

.presence-button {
  span {
    overflow: inherit;
  }
}

.presence-item {
  display: flex;
  align-items: center;
}

.ui-button__content {
  display: flex;
}

.ui-popup__content {
  .ui-popup__content__content {
    padding: 0;

    .presence-submenu {
      padding-bottom: 0;
      padding-top: 0;
      li {
        border: 0;
        .presence-submenu-item {
          padding-left: 16px;
          padding-right: 12px;
        }
      }
    }
    .presence-submenu-item {
      min-height: 32px;
    }

    .ms-FocusZone {
      .ui-list__item:hover {
        background-color: #efefef;
        color: black;
      }
    }
  }
}

.presence-menu>div>div>ul>li:nth-child(6),
.presence-menu>div>div>ul>li:nth-child(8) {
  pointer-events: none;
  border: 0px;
  a {
    padding: 0px;
  }
}

li .break-line {
  padding: 0px;
  cursor: default;

  span {
    width: 100%;
    margin: 0px;

    hr {
      border-top: 1px solid #efefef;
      border-left: 1px solid #efefef;
    }
  }
}

.logout-button {
  width: 13.4rem;
}

.logout-button-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 10.6rem;
  display: inline-block;
}

.reset-button {
  width: 13.4rem;
}

.reset-button-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 10.6rem;
  display: inline-block;
}

.default-app-theme {
  .presence-status {

    &.away,
    &.be-right-back {
      img {
        content: url(/assets/themes/default/AwayIconDefaultTheme.svg);
        width: 10px;
        height: 10px;
      }
    }
  }
}

.dark-app-theme {
  .presence-status {

    &.away,
    &.be-right-back {
      img {
        content: url(/assets/themes/dark/AwayIconDarkTheme.svg);
        width: 10px;
        height: 10px;
      }
    }
  }
}

.contrast-app-theme {
  .presence-status {

    &.away,
    &.be-right-back {
      img {
        content: url(/assets/themes/contrast/AwayIconContrastTheme.svg);
        width: 10px;
        height: 10px;
      }
    }
  }
}

.ui-menu {
  padding-left: 0px !important;
  padding-right: 0px !important;
  li a {
   padding: 8px 16px;
  }
}
