.social-media-handle {
    .social-media-btn {
        color: white;
        // padding: 23px;
        &.accept {
            background-color: #35ad31;
            border-radius: 0px;
            width: 50px;
            height: 50px;
            &:hover {
                color: #ffffff;
                background-color: #2e962a;
            }
        }

        &.ignore {
            background-color: #c4314b;
            border-radius: 0px;
            width: 50px;
            height: 50px;
            &:hover {
                background-color: rgba(167, 32, 55, 0.9);
                color: #ffffff;
            }
        }
    }
}
.social-media-message {
    padding-left: 30px;
}
