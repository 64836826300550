.filter-label {
    background-color: rgb(250, 250, 250) !important;
    white-space: nowrap;
    border: none;
    border-radius: 999rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 1.5rem;
    color: rgb(66, 66, 66) !important;

    &:hover {
        background-color: rgb(224, 224, 224) !important;
        color: rgb(66, 66, 66) !important;
    }
    
    &.selected {
        background-color: rgb(232, 235, 250) !important;
        color: rgb(79, 82, 178) !important;
        
        &:hover {
            background-color: rgb(146, 153, 247) !important;
            color: rgb(79, 82, 178) !important;
        }
    }
}
