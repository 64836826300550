.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 4px;
}

.timeline-container::after {
    background-color: #e17b77;
    content: '';
    position: absolute;
    left: calc(13% - 2px) !important;
    width: 4px;
    height: 100%;
}

.timeline-item {
    display: flex;
    justify-content: flex-start;
    align-self: flex-end;
    padding-left: 30px;
    position: relative;
    margin: 10px 0;
    height: 10%;
    width: 95% !important;
}

.timeline-item-content {
    width: 300px;
    max-width: 70% !important;
}

.timeline-item-content .circle {
    background-color: #fff;
    border: 3px solid #e17b77;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(8% - 8px);
    width: 20px;
    height: 20px;
    z-index: 1;
}

.start-end-container {
    justify-content: center !important;
    width: 60px;
    height: 60px;
    border-radius: 50% !important;
    padding-top: 10px;
    position: relative;
    left: calc(13% - 30px);
    z-index: 1;
}

@media only screen and (max-width: 1023px) {
    .timeline-item-content {
        max-width: 100%;
    }
}
@media only screen and (max-width: 767px) {
    .timeline-item-content {
        padding: 15px 10px;
        text-align: center;
        align-items: center;
    }
}

.historical-convo-icon {
    width: 18px;
    height: 18px;
}

.timeline-icon {
    padding-left: 0.5em;
}