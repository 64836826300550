#root {
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
        width: 7px;
    }
}
.error-list {
    li {
        display: contents;
    }
    width: 100%;
}

.error-list .ui-list__item__content {
    margin-right: 0px;
}

.main-view-container {
    flex-wrap: wrap;

    .show-hide-left-panel-btn {
        position: absolute;
        top: 370px;
        transform: translate(0, -50%);
        z-index: 6;
        left: 0;
        transition: left 0.5s;

        &.active {
            left: 270px;
            transition: left 0.5s;

            .arrow {
                margin-left: 12px;
                margin-right: 0px;
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
            }
        }

        .arrow {
            margin-right: 8px;
            border: solid white;
            border-width: 0px 2px 2px 0;
            display: inline-block;
            padding: 6px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }
    }

    .app-header-container {
        display: block;
        width: 100%;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        align-self: flex-start;
        z-index: 3;
        .web-chat-wrapper {
            display: block;
        }
    }

    .app-body-container {
        display: block;
        width: 100%;
    }

    .app-body-container>div {
        max-height: 87vh;
    }

    .calls-container {
        &:hover {
            ::-webkit-scrollbar {
                display: block i !important;
            }
        }

        flex-grow: 1;
        overflow-y: auto;
        margin-left: 10px;

        .media-container {
            padding: 10px 10px 10px 0px;
        }
    }

    .navigation-container {
        background-color: white;
        position: relative;
        overflow-y: auto;

        &:before {
            background: linear-gradient(to right, transparent, #aaa);
            content: "";
            height: 100%;
            opacity: 0.4;
            pointer-events: none;
            position: absolute;
            right: 0;
            width: 10px;
            z-index: 2;
        }

        .navigation-container-menu {
            width: 100%;

            li:first-child {
                margin-left: auto;
            }

            li:last-child {
                margin-right: auto;
            }
        }
    }

    .app-left-panel {
        overflow-y: auto;
        margin-bottom: 3em;
    }
}

@media (max-width: 959px) {
    .app-left-panel {
        flex: 0 0 0em;
        width: 0em;
        position: absolute;
        font-size: 0.8rem;
        overflow-y: auto;
        transition: width 0.5s;
        z-index: 5;
        max-height: 84vh;
        margin-bottom: 3em;
        overflow-x: visible;

        &.active {
            flex: 0 0 19em;
            width: 270px;
            transition: width 0.5s;
        }

        &.no-display {
            flex: 0 0 0 !important;
        }
    }

    .show-hide-left-panel-btn {
        &.active {
            display: block;
            transition: display 2s;
        }
    }

    .web-chat-container {
        .chat-list-container {
            .chat-list-segment {
                min-height: 34em !important;
            }
        }
    }
}

@media (min-width: 960px) and (max-width: 1349px) {
    .app-left-panel {
        flex: 0 0 265px;

        &.no-display {
            flex: 0 0 0 !important;
        }
    }

    .left-panel-trigger-buttons {
        display: none;
    }
}

@media (min-width: 1350px) and (max-width: 1499px) {
    .app-left-panel {
        flex: 0 0 305px;

        &.no-display {
            flex: 0 0 0 !important;
        }
    }

    .left-panel-trigger-buttons {
        display: none;
    }
}

@media (min-width: 1500px) {
    .app-left-panel {
        flex: 0 0 345px;

        &.no-display {
            flex: 0 0 0 !important;
        }
    }

    .left-panel-trigger-buttons {
        display: none;
    }
}