.web-chat-container {
    // height: 100%;
    // min-height: 450px;
    // overflow: auto;
    .web-chat-message-container {
        min-height: 31em;
    }
    .chat-list-container {
        padding-bottom: 10px;
        min-height: 400px;

        .chat-list-segment {
            height: 100%;
            overflow: auto;
            min-height: 31em;
        }
    }

    .messages-segment {
        height: 80%;
        min-height: 23em !important;

        ul {
            padding-bottom: 30px;
        }

        .messages-component {
            margin-top: 10px;
            height: 100%;
            min-height: 20em;
            max-height: 35em;
            overflow: auto;

            .chat-error {
                color: #a72037 !important;
                .ui-chat__message__content {
                    color: #a72037 !important;
                }
            }
        }

        .messages-text {
            color: #605e5c;
        }
    }
}
