.supervisor-presence-status{
    margin-right: 10px;
    margin-top: 5px;
}

.poiting-cursor{
    cursor: pointer;
}

.invalid-value input{
    border-bottom: 0.125rem solid red;
}

.invalid-value input:focus{
    border-bottom: 0.125rem solid red;
}

