.wrapup-dropdown {
    button {
        padding-left: 11px;
        padding-right: 0px;
    }

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        text-align: left;
    }

    ul {
        cursor: pointer;

        li div div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
        }
    }
}

.wrap-codes-dropdown-extended {
    width: 250px;

    .ui-dropdown__container {
        width: 250px;
    }

    .ui-dropdown__items-list {
        width: 250px;
    }
}