.chat-list-item{
    &.highlighted{
        font-weight: 900;
    }

    .chat-hover{
        display: block;
    }
    
    .chat-options{
        display: none;
    }

    .chat-options-disabled{
        display: none;
    }

    &:hover{
        .chat-hover{
            display: none;
        }
        
        .chat-options{
            display: block;
        }
    }
}

.chat {
    width: 100%;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    display:inline-block;
}

.transfer-message {
    padding: 5px;
    display: inline-table;
    max-width: 200px;
}

.chat-sessions-text{
    color: #605E5C;
}