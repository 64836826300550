.custom-agenda-header {
    margin: var(--agenda-header-margin, 40px 10px 14px 10px);
    font-family: var(--default-font-family);
    font-size: var(--agenda-header-font-size, 24px);
    color: var(--agenda-header-color, var(--color-sub1, #323130));
}

.custom-event {
    background: var(--event-background-color, #fff);
    border: var(--event-border, solid 2px transparent);
    box-shadow: var(--event-box-shadow, 0 2px 8px var(--box-shadow-color, rgba(0, 0, 0, .092)));
    margin: var(--event-margin, 0 10px 14px 10px);
    padding: var(--event-padding, 0);
    display: flex;
    border-radius: 8px;

    .event-time-container {
        display: flex;
        flex-direction: column;
        margin: 18px 38px 18px 18px;
        width: 125px;

        .event-time {
            font-size: var(--event-time-font-size, 12px);
            color: var(--event-time-color, var(--color, #000));
            font-weight: 600;
        }
    }

    .event-details-container {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        font-family: var(--default-font-family);
        font-style: normal;
        font-weight: 400;

        .event-subject {
            margin: 0px 18px 0px 0px;
            font-size: var(--event-subject-font-size, 19px);
            color: var(--event-subject-color, var(--color-sub1, #323130));

            &.center-vertically {
                margin-top: 12px;
            }
        }

        .event-location-container {
            display: flex;
            margin: 8px 18px 0px 0px;

            .event-location-icon {
                svg {
                    height: 12px;
                }
            }

            .event-location {
                font-size: var(--event-location-font-size, 12px);
                color: var(--event-location-color, var(--color, #000));
                margin: 0px 0px 0px 4px;
                line-height: 17px;
            }
        }
    }
}