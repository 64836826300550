.default-app-theme {
    .away-icon {   
        content: url(/assets/themes/default/AwayIconDefaultTheme.svg);        
    }
}

.dark-app-theme {
    .away-icon {  
        content: url(/assets/themes/dark/AwayIconDarkTheme.svg);        
    }
}

.contrast-app-theme {
    .away-icon {     
        content: url(/assets/themes/contrast/AwayIconContrastTheme.svg);        
    }
}