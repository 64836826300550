.call-type-icon-color {
    color: #a1a1a1;
}

.waiting-list-table .pickup-button,
.waiting-list-table .pickup-button:hover,
.waiting-list-table .pickup-button:active {
    background-color: #9fc25f;
    box-shadow: none;
}

.social-media-icon-container {
    .ui-table__cell__content {
        width: 15px;
    }
}
.default-app-theme {
    .social-media-icon {
        content: url(/assets/themes/default/SocialMediaIconDefaultTheme.svg);
    }
}

.dark-app-theme {
    .social-media-icon {
        content: url(/assets/themes/dark/SocialMediaIconDarkTheme.svg);
    }
}

.contrast-app-theme {
    .social-media-icon {
        content: url(/assets/themes/contrast/SocialMediaIconContrastTheme.svg);
    }
}

.pickup-column {
    div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (max-width: 960px) {
    .table-header-column {
        display: none !important;
    }

    .pickup-column {
        flex-grow: 0.3 !important;
        div {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
