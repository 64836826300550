.stop-recording-button {
    .stop-recording-icon {
        min-width: 20px;
        height: 20px;
        bordeR: 2px solid #c4314b;
        border-radius: 50%;
        background-color: #c4314b;
        cursor: pointer;
    }

    &:hover {
        .stop-recording-icon {
            background-color: rgba(255, 170, 68, 0);
        }
    }
}

#cold-transfer-dropdown {
    cursor: pointer;
}