.queue-list-container {
    overflow: scroll;
    height: 175px;

    .queueList li {
        min-height: 0px;
        padding: 0px;

        .queues-checkbox {
            min-height: 0px;

            span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}

.contact-field-container {
    width: 47%;
    margin-right: 8px;

    .field-error {
        font-size: 12px;
        color: rgb(196, 49, 75);
    }
}

.column-placeholder {
    width: 50%;
}

.extra-fields-button {
    width: 100%;
    align-items: start;

    button {
        padding-left: 0px;
    }
}

.error-message {
    padding-bottom: 12px;
}