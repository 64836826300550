:root {
    --ck-border-radius: 0;

    --em-padding: 1rem;
    --em-negative-padding: -1rem;
}

.ck-content {
    --ck-color-base-border: hsl(0, 0%, 100%);
}

.ck.ck-toolbar {
    border: none !important;
    padding-top: 1rem;
}

.em-container {
    background-color: white;
    border-radius: 0.2rem;
    padding: var(--em-padding);
}

.em-send-button {
    min-width: 0 !important;
    padding-left: 0.9rem !important;
    padding-right: 0.9rem !important;
}

.em-divider-padding {
    margin-left: var(--em-negative-padding);
    margin-right: var(--em-negative-padding);
}

.em-header {
    min-height: 3.063rem;
}

.em-attachment {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.em-mainContainer {
    background-color: #fff;
    border-radius: 0.2rem;
    padding: var(--em-padding);
}

.em-bottomContainer {
    padding-top: var(--em-padding);
}

.em-sendButton {
    min-width: 0 !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}

.em-recipient-text {
    font-size: 0.75rem;
    max-width: 15rem;
}

.em-recipient-container {
    border: 1px solid #D1D1D1;
    border-radius: 0.2rem;
    padding: 0.25rem 0.5rem;
    width: fit-content;
    margin-top: 0.46875rem;
    margin-bottom: 0.46875rem;
}

.em-recipient-search-container {
    max-height: 18rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.em-recipient-search-input-error {
    margin: 0.5rem;
    border-radius: 0.2rem;
    background-color: #fff;
}
