.active-tab {
    display: block !important;
}

.hidden-tab {
    display: none !important;
}

.tab-navigation {
    background-color: white;
    margin-bottom: 20px;
    padding: 0 5px;

    .tab-navigation-button {
        margin: 0 5px;
        cursor: pointer;
    }
}

.menu-tabs-container {
    .menu-tabs {
        border-bottom: none;
    }

    .menu-links {
        .external-link {
            &:hover {
                border-bottom-color: rgba(225, 223, 221, 0);
            }

            span {
                text-decoration: underline !important;
            }
        }
    }
}

@media (max-width: 960px) {
    .hideTab {
        display: none !important;
    }
}

.default-app-theme {
    .incoming-call-icon {   
        content: url(/assets/themes/default/IncomingCallIconDefaultTheme.svg);        
    }

    .outgoing-call-icon {   
        content: url(/assets/themes/default/OutgoingCallIconDefaultTheme.svg);        
    }
}

.dark-app-theme {
    .incoming-call-icon {  
        content: url(/assets/themes/dark/IncomingCallIconDarkTheme.svg);        
    }

    .outgoing-call-icon {      
        content: url(/assets/themes/dark/OutgoingCallIconDarkTheme.svg);        
    }
}

.contrast-app-theme {
    .incoming-call-icon {     
        content: url(/assets/themes/contrast/IncomingCallIconContrastTheme.svg);        
    }

    .outgoing-call-icon {       
        content: url(/assets/themes/contrast/OutgoingCallIconContrastTheme.svg);
    }
}

.missed-call-icon { 
    content: url(/assets/themes/default/MissingCallIconDefaultTheme.svg);    
}

.tab-underline{
    border-bottom-width: 0.25rem;
    border-color: transparent;
    border-bottom-style: solid;

    &:hover{
        padding-bottom: 8px !important;
    }
}

.call-history-tooltip-content{
    padding: 5px 10px !important;
    span{
       
        font-size: 12px !important;
    }
}

.ui-tooltip__content.pl{
    max-width: none !important;
}

@media (max-width: 960px) {
    .table-header-column {
        display: none !important;
    }
}