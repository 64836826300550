.queue-list{
    .queue-list-item{
        .queue-check-icon{
            margin: 0 15px;
            margin-left: 5px;
            visibility: hidden;
            &.selected{
                visibility: visible;
            }
        }
        .queue-item-content{
            min-height: 36px;
            padding: 10px;
        }
    }
}