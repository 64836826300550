.waiting-list-table {
    min-height: 75px;
}

.call-history-table {
    min-height: 75px;
}

.supervisor-table {
    min-height: 30em;
}

.smallest-grow {
    flex-grow: 0.2 !important;
    div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.smaller-grow {
    flex-grow: 0.5 !important;
    div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.small-grow {
    flex-grow: 0.8 !important;
    div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.jend {
    justify-content: flex-end;
}

.big-grow {
    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.bigger-grow {
    flex-grow: 1.1 !important;
    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.biggest-grow {
    flex-grow: 1.3 !important;
    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.bigger-grow.display-name-cell{
    width: 100%;
    div{
        width: 100%;
    }
}

.caller-phone-column, .caller-name-column {
    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 960px) {
    .caller-phone-column, .caller-name-column {
        flex-grow: 2 !important;
        div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
