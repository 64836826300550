.dialpad-view {
    padding: 20px;
}

.endpoint-dropdown {
    button {
        padding-right: 0;
        padding-left: 5px;
    }

    button span {
        font-size: medium;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
    }

    ul {
        cursor: pointer;
    }

    li div div {
        font-size: medium;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        width: 90%;
    }
}

.endpoint-dropdown>div>div:first-child {
    padding-right: 25px;

    span {
        font-size: 11px;
    }
}

.endpoint-dropdown>div>ul>li>div:first-child>div {
    font-size: 11px;
}

.phone-number-input {
    input {
        color: #6264A7;
        text-align: center;
        font-size: large;
        font-weight: 400;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 35px;
        padding-left: 5px;

        ::-webkit-input-placeholder {
            color: #6264A7;
        }
    }

    .disable-clear-button {
        display: none;
    }
}

.phone-number-input-disabled {
    input {
        text-align: center;
        font-size: large;
        font-weight: 400;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 35px;
        padding-left: 5px;
    }

    .disable-clear-button {
        display: none;
    }
}

.call-button {
    min-height: 40px;
    width: 40px;
}

.tab-underline{
    border-bottom-width: 0.25rem;
    border-color: transparent;
    border-bottom-style: solid;

    &:hover{
        padding-bottom: 8px !important;
    }
}