.favorites-contacts-container {
    min-height: 450px;
    overflow-y: auto;

    .favorites-contacts-list {
        width: 100% !important;

        .fav-item {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }
}
.navigation-container {
    .favorite-avatar-item {
        max-width: 200px;
        overflow-x: hidden;

        div div:nth-child(2) {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .fav-transfer-visible {
        .favorite-avatar-item {
            max-width: 150px;
        }
    }
}

.fav-contact-menu-call {
    li.ui-menu__itemwrapper {
        min-width: 360px;
    }
}

.default-app-theme {
    .active-favorite {
        color: rgb(98, 100, 167) !important;
        svg {
            fill: rgb(98, 100, 167) !important;
            path {
                &.ui-icon__filled {
                    display: inline !important;
                }
            }
        }
    }
}

.dark-app-theme {
    .active-favorite {
        color: rgb(166, 167, 220) !important;
        svg {
            fill: rgb(166, 167, 220) !important;
            path {
                &.ui-icon__filled {
                    display: inline !important;
                }
            }
        }
    }
}

.contrast-app-theme {
    .active-favorite {
        color: black !important;
        background: rgb(26, 235, 255);
        svg {
            fill: black !important;
            path {
                &.ui-icon__filled {
                    display: inline !important;
                }
            }
        }
    }
}

@media (max-width: 959px) {
    .navigation-container {
        .favorite-avatar-item {
            max-width: 460px;
            overflow-x: hidden;
        }
        .fav-transfer-visible {
            .favorite-avatar-item {
                max-width: 440px;
            }
        }
    }
}

@media (min-width: 960px) and (max-width: 1349px) {
    .navigation-container {
        .favorite-avatar-item {
            max-width: 175px;
        }
        .fav-transfer-visible {
            .favorite-avatar-item {
                max-width: 150px;
            }
        }
    }
}
