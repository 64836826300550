.filter-checkbox {
    padding-top: 0px !important;
    div {
        margin-right: -10px;
    }
}

.active-call-icon {
    color: #9fc25f;
}
.social-media-icon-container {
    .ui-table__cell__content {
        width: 15px;
    }
}
.default-app-theme {
    .social-media-icon {
        content: url(/assets/themes/default/SocialMediaIconDefaultTheme.svg);
    }
    .ear-icon {
        content: url(/assets/themes/default/ear.svg);
    }
}

.dark-app-theme {
    .social-media-icon {
        content: url(/assets/themes/dark/SocialMediaIconDarkTheme.svg);
    }
    .ear-icon {
        content: url(/assets/themes/dark/ear.svg);
    }
}

.contrast-app-theme {
    .social-media-icon {
        content: url(/assets/themes/contrast/SocialMediaIconContrastTheme.svg);
    }
    .ear-icon {
        content: url(/assets/themes/contrast/ear.svg);
    }
    .ear-icon:hover {
        content: url(/assets/themes/contrast/ear-hover.svg);
    }
}

.ear-icon {
    width: 16px;
    height: 16px;
}

.ear-icon-call-action {
    width: 20px;
    height: 20px;
    content: url(/assets/themes/contrast/ear.svg);
}

.ear-icon-call-action-active {
    width: 20px;
    height: 20px;
    content: url(/assets/themes/contrast/ear-hover.svg);
}

.actions-column {
    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }   
}

@media (max-width: 960px) {
    .table-column {
        display: none !important;
    }

    .actions-column {
        flex-grow: 0.3 !important;
        div {
            overflow: hidden;
            text-overflow: ellipsis;
        
        }
    }
}