.hide-unpark-button {
    display: none !important;
}

.show-unpark-button {
    display: inline-block !important;
}
.social-media-icon-container {
    .ui-table__cell__content {
        width: 15px;
    }
}

.default-app-theme {
    .social-media-icon {
        content: url(/assets/themes/default/SocialMediaIconDefaultTheme.svg);
    }
}

.dark-app-theme {
    .social-media-icon {
        content: url(/assets/themes/dark/SocialMediaIconDarkTheme.svg);
    }
}

.contrast-app-theme {
    .social-media-icon {
        content: url(/assets/themes/contrast/SocialMediaIconContrastTheme.svg);
    }
}

.unPark-column {
    flex-grow: 0.2 !important;
    div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (max-width: 960px) {
    .table-column {
        display: none !important;
    }

    .unPark-column {
        flex-grow: 0.3 !important;
        div {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
