.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    margin-bottom: 10px;
    
}
.pagination button{
    padding: 8px;
    cursor: pointer;
}
.pagination button:focus{
    border: 1px solid #ffff !important;
    outline: none;
}

.page-item .page-link {
    color: rgb(98, 100, 167);
}

.ui-flex .pagination{
    justify-content: flex-end;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
    text-decoration:none;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}