.add-edit-dialog div {
    margin-bottom: 0px;
}
.btnTransfer {
    margin-left: -10px;
    min-width: 0 !important;
    text-align: left;
}

.change-contact-popup {
    .alert-message {
        text-align: center;
        border: 1px solid rgba(#34495e, 0.25);
        border-radius: 3px;
        line-height: 30px;
        position: absolute;
        top: 12px;
        display: block;
        width: 50%;
        padding: 12px;
        box-sizing: border-box;
        color: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.6);
        background: #464775;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .close {
        float: right;
        color: white;
        font-size: 25px;
        cursor: pointer;
        cursor: hand;
    }
}

.default-app-theme {
    .active-favorite {
        color: rgb(98, 100, 167) !important;
        svg {
            fill: rgb(98, 100, 167) !important;
            path {
                &.ui-icon__filled {
                    display: inline !important;
                }
            }
        }
        &:hover {
            color: rgb(72, 70, 68) !important;
            svg {
                fill: rgb(72, 70, 68) !important;
            }
        }
    }

    .favorite-star-button {
        &:hover {
            color: rgb(72, 70, 68) !important;
        }
    }

    .sip-icon {
        content: url(/assets/themes/default/SipDefaultTheme.svg);
    }

    .mobile-phone-icon {
        content: url(/assets/themes/default/MobilePhoneDefaultTheme.svg);
        margin-right: 0.5rem !important;
    }

    .business-phone-icon {
        content: url(/assets/themes/default/BusinessPhoneDefaultTheme.svg);
    }
}

.dark-app-theme {
    .active-favorite {
        color: rgb(166, 167, 220) !important;
        svg {
            fill: rgb(166, 167, 220) !important;
            path {
                &.ui-icon__filled {
                    display: inline !important;
                }
            }
        }
        &:hover {
            color: rgb(200, 198, 196) !important;
            svg {
                fill: rgb(200, 198, 196) !important;
            }
        }
    }

    .favorite-star-button {
        &:hover {
            color: rgb(200, 198, 196) !important;
        }
    }

    .sip-icon {
        content: url(/assets/themes/dark/SipDarkTheme.svg);
    }

    .mobile-phone-icon {
        content: url(/assets/themes/dark/MobilePhoneDarkTheme.svg);
        margin-right: 0.5rem !important;
    }

    .business-phone-icon {
        content: url(/assets/themes/dark/BusinessPhoneDarkTheme.svg);
    }
}

.contrast-app-theme {
    .active-favorite {
        color: black !important;
        background: rgb(26, 235, 255);
        svg {
            fill: black !important;
            path {
                &.ui-icon__filled {
                    display: inline !important;
                }
            }
        }
        &:hover {
            color: rgb(200, 198, 196) !important;
            svg {
                fill: rgb(200, 198, 196) !important;
            }
        }
    }

    .favorite-star-button {
        &:hover {
            color: rgb(200, 198, 196) !important;
        }
    }

    .sip-icon {
        content: url(/assets/themes/contrast/SipContrastTheme.svg);
    }

    .mobile-phone-icon {
        content: url(/assets/themes/contrast/MobilePhoneContrastTheme.svg);
        margin-right: 0.5rem !important;
    }

    .business-phone-icon {
        content: url(/assets/themes/contrast/BusinessPhoneContrastTheme.svg);
    }
}

.callable-item {
    overflow: hidden;
    max-width: 350px;
}

.callable-item-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.callable-item-readonly {
    cursor: not-allowed !important;
}

.contacts-display-name {
    div div {
        display: block !important;
    }
}

.contacts-actions {
    flex-grow: 0.2 !important;
    min-width: 180px !important;
    div {
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.user-agenda-popup {
    transform: none !important;
    inset: unset !important;
    width: calc(100% - 100px);
    top: 120px !important;
    left: 50px !important;

    .user-agenda-popup-content {
        padding: 1vh;
        height: 70vh;
        overflow-y: scroll;
        .agenda-content-container {
            height: 68vh;

            .no-agenda-found {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 4vh;
            }

            .agenda-close-button {
                svg {
                    position: absolute;
                    right: 33px;
                    float: right;
                    top: 16px;
                    height: 20px;
                    width: 17px;
                    cursor: pointer;
                    z-index: 2;
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .add-new-contact-button,
    .main-search,
    .calendar,
    .table-column {
        display: none !important;
    }
    .responsive-search {
        display: block !important;
        margin-top: 5px;
    }
}

@media (min-width: 960px) {
    .responsive-search {
        display: none !important;
    }
}
