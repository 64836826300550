@media (max-width: 960px) {
    .dialog-box {
        width: 600px !important;
        height: 600px !important;
    }

    .settings-informations{
        margin-top: -165px;
    }
}

@media (max-width: 600px) {
    .dialog-box {
        width: 350px !important;
        height: 450px !important;
    }

    .settings-informations{
        margin-top: -115px;
    }
}