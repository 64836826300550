.generic-container {
    .web-chat-message-container {
        min-height: 31em;
    }
    .chat-list-container {
        padding-bottom: 10px;
        min-height: 400px;

        .chat-list-segment {
            height: 100%;
            overflow: auto;
            min-height: 31em;
        }
    }
}

