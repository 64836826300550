.alert-info {
    display: flex;
    flex-wrap: wrap;

    .alert-message {
        white-space: normal;
        text-overflow: ellipsis !important;
        max-height: 58px;
        overflow: hidden !important;
    }
}
.full-width {
    flex: 0 0 100%;
    margin: 3px 0px;
}

.bold-text {
    font-weight: 900 !important;
}
.cursor-pointer {
    cursor: pointer;
}

.alert-row-item {
    min-height: 60px !important;
    height: auto !important;
}
